<template>
    <!-- eslint-disable -->
    <div class='page' :class="{mobile: mobile}">
        <div class="regprotocol fs_16">
            <p class="regprotocol-title fs_22">用户服务协议及个人信息隐私政策</p>
            <p class="center fs_12 mt_10">
                开发者：深圳市职路信息技术有限公司（统称为“职路数字”或“我们”）
            </p>
            <p class="pdttb20">
                　　欢迎使用职路数字平台，在您使用职路数字平台提供的各项服务之前，请务必仔细阅读并透彻理解《用户服务协议》（以下简称本协议）。<br>
                　　如您不同意本协议及其更新内容，您可以主动取消职路数字平台提供的服务；您一旦完成职路数字平台的用户注册，即视为您已了解并完全同意本协议的各项内容，并成为职路数字平台用户（以下简称“用户”）。职路数字平台有权在必要时修改用户协议，协议一旦发生变动，将会以网站公告的形式提示修改内容，变动结果将在职路数字平台通知之日起生效。如果您不同意所改动的内容，您有权停止使用职路数字平台的服务，如果您继续使用职路数字平台的各项服务，则视为接受服务条款的变动。职路数字平台保留修改或中断服务的权利。职路数字平台尊重并保护所有使用职路数字平台服务用户的个人隐私。为了给您提供更加准确、更个性化的服务，职路数字平台会按照本隐私权政策的规定使用和披露您的个人信息。但职路数字平台将以高度的勤勉、审慎的态度对待您的个人信息。除非有法律和政府的强制规定，在事先未征得您许可或者授权的情况下，职路数字平台不会将您的信息对外披露或者向第三方提供。职路数字平台会不时更新本隐私权政策。您在同意职路数字平台用户服务协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于职路数字平台用户服务协议不可分割的一部分。除本协议外，职路数字平台发布的其他相关的业务规则也是本协议的一部分，请您仔细阅读。</p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">一、服务内容</span>
                　　1、职路数字平台为企业的离职员工办理双方签署的离职证明申请。
                <br>　　2、企业明确授权确认此次的内容。
                <br>　　3、企业在平台招聘符合条件的员工。
                <br>　　4、员工在平台进行求职或搜寻灵活用工的工作。
                <br>　　5、您需充分理解，在使用职路数字平台时所产生的网络服务有关的设备及所需的费用（如为使用移动网络而支付的手机费、流量费等各项费用）均应由您自行负担。
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">二、用户注册及使用规范</span>
                　　1、职路数字平台账号的所有权归我们所有，您完成申请注册手续后，将获得所注册账号的使用权，该使用权仅限于您使用，您无权赠与、借用、租用、转让或售卖您的账号。
                <br>　　2、维护账号及密码的安全是您的责任，您应对您的账户的活动负全部的责任。
                <br>　　3、您在使用职路数字平台时，所发布的信息（包括企业评价功能、用户注册名、用户头像等），必须遵守国家有关法律规定，并承担一切因自己发布信息不当导致的民事、行政、或刑事法律责任。您在使用职路数字平台所发布的消息，不得含有以下内容：
                <br>　　• 违反宪法确定的基本原则的；
                <br>　　• 危害国家安全，泄露国家机密，颠覆国家政权，破坏国家统一的；
                <br>　　• 损害国家荣誉和利益，攻击党和政府的；
                <br>　　• 煽动民族仇恨、民族歧视，破坏民族团结的；
                <br>　　• 破坏国家、地区间友好关系的；
                <br>　　• 违背中华民族传统美德、社会公德、论理道德、以及社会主义精神文明的；
                <br>　　• 破坏国家宗教政策，宣扬邪教和封建迷信的；
                <br>　　• 散布谣言或不实消息，扰乱社会秩序，破坏社会稳定的；
                <br>　　• 煽动、组织、教唆恐怖活动、非法集会、结社、游行、示威、聚众扰乱社会秩序的；
                <br>　　• 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
                <br>　　• 侮辱或诽谤他人，侵害他人合法权益的；
                <br>　　• 侵犯他人肖像权、姓名权、名誉权、隐私权或其他人身权利的；
                <br>　　• 以非法民间组织名义活动的；
                <br>　　• 侵犯他人著作权等合法权益的；
                <br>　　• 侵犯他人商业秘密等合法权益的；
                <br>　　• 含有法律、行政法规禁止的其他内容的。
                <br>　　4、如果您在职路数字平台发布信息时不能履行和遵守协议中的规定，为维护职路数字平台的形象、荣誉、安全，我们有权删除您发布的相关信息，直到封闭您的账号或和暂时、永久禁止在职路数字平台发布信息的处理，同时保留依法追究您的法律责任的权利，我们在职路数字平台的系统中的记录有可能作为您违反法律和本协议的证据。
                <br>　　5、未经职路数字平台许可，任何单位或个人不得通过任何方式（包括但不限于恶意注册职路数字平台账号，机器抓取、复制、镜像等方式）不合理地获取职路数字平台站内信息、资讯、数据等。
                <br>　　6、职路数字平台收集的信息均是用户自主填写提供，用户在注册成为职路数字平台的用户时即已同意授权职路数字平台将其收集的信息提供给合作的第三方，用户可以随时登录自己的注册账号修改信息。如用户不填写相关信息或填写信息不完善，职路数字平台将仅根据用户已填写信息和相关部门已公示信息出具相关报告，如对用户产生任何不利影响将由用户自己承担。
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">三、个人信息保护及隐私政策</span>
                　　1、定义与适用范围
                <br>　　1.1 个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息。本政策中涉及的个人信息包括：基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）；个人身份信息（包括身份证、军官证、护照、驾驶证等）；网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（交易和消费记录、以及预付款余额、银行卡信息、微信支付授权、支付宝支付授权、职路数字优惠卷等虚拟财产信息）；通讯录；个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/Android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息））；个人位置信息（包括行程信息、精准定位信息、经纬度等）。
                <br>　　1.2 个人敏感信息，是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。在本政策中包括：手机号码、身份证或其他身份证明、位置信息、行程信息、通话记录、支付信息、提现记录、银行卡号。
                <br>　　1.3 匿名化处理后的信息，即通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后不能被复原的信息，不属于个人信息。
                <br>　　1.4 其他无法识别特定自然人身份或者反映特定自然人活动情况的信息不属于个人信息。

                <br>　　2. 个人信息的收集
                <br>　　2.1 职路数字平台业务功能分为核心业务功能、附加业务功能及第三方提供的服务，我们将详细向您说明各业务功能所收集的个人信息以及收集方法、目的，拒绝提供的影响等。业务功能具体包括：
                <br>　　2.1.1 核心业务功能，包括创建账号并完善资料、查看招聘信息、发布求职需求、完成灵活用工业务等。
                <br>　　2.1.2 附加业务功能，包括用户评价、客服服务及运营活动。
                <br>　　2.1.3 第三方提供的服务，包括但不限于招聘服务。
                <br>　　2.2 您使用职路数字产品或服务前，需要在职路数字平台创建账号，完善个人资料。我们会收集您的以下信息：
                <br>　　2.2.1 您需要向我们提供您的手机号码。如果您选择了使用“微信登录”或“支付宝登录”方式我们还获取您在微信平台或支付宝平台的OpenID。我们以此识别您在职路数字平台的网络身份，并为您提供职路数字平台服务。如果您拒绝提供手机号码，职路数字平台将无法为您创建账号并提供服务。
                <br>　　2.2.2 您可以选择向我们提供姓名、身份证号、性别、生日等。您可以在个人信息资料中选择是否填写或授权职路数字获取上述信息，以完善您的个人资料。如您不提供上述信息，不会影响您使用核心业务功能。
                <br>　　2.3 根据您使用的具体业务功能，我们可能还会收集您的以下信息：
                <br>　　2.3.1 位置信息。我们会收集您的位置信息，以便您不需要手动输入地理坐标即可获得相关的服务。在您首次使用职路数字平台前，我们会提示您选择是否允许职路数字平台获取以及何种情况下可以获取您的位置信息。在您使用职路数字产品或服务的过程中，您可以随时通过您的设备设置功能，选择是否允许职路数字平台获取以及何种情况下可以获取您的位置信息。获得您的允许后，我们会通过IP地址、GPS以及能够提供相关信息的其他传感器（如附近设备、Wi-Fi接入点和基站信息）来校准您的位置信息，以便于您更准确地选择求职地点等服务。
                <br>　　2.3.2 您在职路数字平台进行交易支付时，如果使用第三方支付功能，职路数字平台除获取您的支付工具及订单支付状态、充值提现信息外，不会获取其他个人信息。
                <br>　　2.3.3 为了防控运营风险，保障您的账号安全，满足法律法规要求，开展数据分析、更好改善职路数字平台服务，我们会收集您的设备信息（包括设备型号，操作系统版本，设备设置，MAC地址，IMEI、Android ID 、IDFA、IDFV、OAID及其他设备标识符，SIM卡IMSI信息，SIM卡归属地，设备环境，软件安装列表，以及其他软硬件特征信息）及日志信息（包括浏览记录、检索内容、点击查看记录、交易记录以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间）。
                <br>　　2.4 您通过第三方平台使用职路数字服务时，职路数字将会间接向第三方收集您的手机号码、位置信息、订单信息以及设备号，以识别您的身份、确定您的位置并向您提供服务。职路数字向第三方收集上述个人信息前，将对个人信息来源的合法性进行确认。
                <br>　　2.5 请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且需要收集您的信息，我们会通过页面提示、交互流程、网站公告、另行签署协议或其他方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。

                <br>　　3. 设备权限调用
                <br>　　3.1 职路数字可能会调用您的一些设备权限，以下是调用权限对应的业务功能、我们调用权限的目的，以及调用权限前向您询问的情况。
                <br>　　3.2 您可以在设备的设置功能中选择关闭部分或全部权限，从而拒绝职路数字平台收集相应的个人信息。使用ios或Android系统的不同设备中，权限显示方式及关闭方式可能有所不同，如果无法找到相应功能，可询问设备及系统的提供方获得帮助。
                <br>　　3.3 设备权限调用
                <el-table :data="tableData" style="width: 100%" border>
                    <el-table-column prop="auth" label="设备权限" align="center"></el-table-column>
                    <el-table-column prop="function" label="业务功能" align="center"></el-table-column>
                    <el-table-column prop="objective" label="调用权限的目的" align="center"></el-table-column>
                    <el-table-column prop="inquiry" label="是否询问" align="center"></el-table-column>
                    <el-table-column prop="closed" label="可否关闭" align="center"></el-table-column>
                </el-table>
                <br>　　3.4 第三方 SDK
                <el-table :data="sdkTableData" style="width: 100%" border>
                    <el-table-column prop="auth" label="SDK名称" align="center"></el-table-column>
                    <el-table-column prop="function" label="中文名称" align="center"></el-table-column>
                    <el-table-column prop="objective" label="收集信息" align="center"></el-table-column>
                    <el-table-column prop="inquiry" label="目的" align="center"></el-table-column>
                </el-table>

                <br>　　4. 我们如何使用Cookie及同类技术
                <br>　　4.1 Cookie是支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制。当您使用职路数字产品或服务时，为使您获得更轻松安全的访问体验，我们可能会使用Cookie或同类技术来收集和存储信息，在此过程中可能会向您的设备发送一个或多个 Cookie 或匿名标识符。这么做是为了收取您的信息，用于了解您的偏好，进行咨询或数据分析，改善产品服务及用户体验，或及时发现并防范安全风险，为您提供更好的服务。我们不会将Cookie用于本政策所述目的之外的任何用途，您可根据自己的偏好留存或删除Cookie。您可清除软件内保存的所有Cookie，当您手动清除后您的相关信息即已删除。

                <br>　　5. 个人信息的保存
                <br>　　5.1 信息保存期限
                <br>　　5.1.1 在用户使用职路数字产品或服务期间，我们会持续保存用户的个人信息。
                <br>　　5.1.2 当您自主删除个人信息或注销账号，我们将按照法律、法规规定的最短期限保留您的现有个人信息，在法律法规要求的最短保存期限内，我们将不再对您的个人信息进行商业化使用。当您的个人信息超出上述保存期限，我们将会对其进行删除或匿名化处理。
                <br>　　a. 为配合人民检察院、公安机关、国家安全机关侦查用户使用职路数字产品或服务过程中产生的犯罪行为，更好保护其他用户生命财产安全，当用户自主删除个人信息或注销账号，我们将在刑法规定的犯罪追诉时效期间内，加密隔离存档用户个人信息。
                <br>　　b. 如您使用职路数字产品或服务的过程中，存在严重违反法律法规、平台协议、平台规则等情形，您的违法、违约记录及相应的平台信用记录，将被永久保存。
                <br>　　5.2 信息存放地域
                <br>　　5.2.1 我们收集的您的个人信息，将在中国内地存储和使用。
                <br>　　5.2.2 如涉及向境外传输个人信息，我们将明确向您告知个人信息出境的目的、接收方、安全保障措施等情况，并另行征得您的同意。
                <br>　　5.3 安全保护措施
                <br>　　5.3.1 本公司将采用严格的安全制度以及行业通行的安全技术和程序来确保您的个人信息不丢失、泄露、毁损或被未经授权的访问、使用。
                <br>　　5.3.2 职路数字采取以下安全技术措施保护您的个人信息：
                <br>　　a. 用户个人敏感信息被加密储存在服务器中，并通过数据隔离技术进行存储。
                <br>　　b. 数据传输过程中使用加密传输协议。
                <br>　　c. 严格控制数据访问权限，设立完善的敏感数据访问权限申请、审批制度。
                <br>　　d. 建立数据安全监控和审计制度，进行全面数据安全控制。
                <br>　　5.3.3 职路数字同时采取其他安全措施保护您的个人信息：
                <br>　　a. 建立公司信息安全委员会，统一协调管理数据安全工作，并下设数据安全小组、推动各项数据安全活动。
                <br>　　b. 公司内部颁布实施数据安全管理规范，明确对用户数据（包括用户个人信息）的保护标准和要求。
                <br>　　c. 新项目、新系统上线前对数据安全（包括用户个人信息数据）进行项目风险评估。
                <br>　　d. 与全体员工及服务外包人员签署保密协议，并严格按照工作职责分配数据访问权限。
                <br>　　e. 定期开展面向公司全体员工及外包服务人员的信息安全教育及培训。
                <br>　　5.4 安全事件处置
                <br>　　5.4.1 为应对个人信息泄露、毁损、丢失等可能出现的安全风险，已经在公司范围内颁布多项制度，明确了安全事故、安全漏洞的分类分级标准，以及针对上述安全事故和漏洞，内部处理流程（包括应急响应流程）和补救措施。
                <br>　　5.4.2 一旦发生安全事件，我们将及时向您告知事件基本情况和风险、我们已经采取或将要采取的措施、您如何自行降低风险的建议等。
                <br>　　5.4.3 我们将及时以推送通知、信函或电话等方式将安全事件情况通知受影响的用户。当难以逐一告知用户时，我们将通过发布平台公告的方式发布警示信息。
                <br>　　5.5 停止运营
                <br>　　5.5.1 如职路数字平台停止运营，我们将至少提前30日在职路数字平台发布公告，并及时停止收集个人信息。
                <br>　　5.5.2 停止运营后，我们将停止对个人信息的商业化使用，并在满足法律法规规定的最短保存期后，对收集的个人信息进行删除或匿名化处理。

                <br>　　6. 个人信息的使用
                <br>　　6.1 您的个人信息会被用于“个人信息的收集”条款明确列明的使用场景。
                <br>　　6.2 您的个人信息可能被用于以下与“个人信息的收集”条款所声称的目的具有直接或合理关联的场景：
                <br>　　a. 我们可能将来自职路数字平台某项服务的个人信息与来自其他项服务的信息结合起来，做出特征模型并进行用户画像，以为您提供更加个性化、便捷的服务。我们承诺在使用用户画像的过程中，不会侵害公民、法人和其他组织的合法权益，不会危害国家安全、荣誉和利益。
                <br>　　b. 我们可能会以去除身份识别信息的个人信息进行统计分析形成的数据为基础，设计、开发、推广全新的产品及服务。
                <br>　　c. 我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息。
                <br>　　d. 我们会向您发送信息和通知，包括为保证服务完成所必需的验证码、使用产品或服务时所必要的推送通知、关于职路数字产品或服务的市场活动及优惠促销信息、职路数字或其合作第三方的推广信息、订单支付提醒通知，或其他您可能感兴趣的内容。如您不希望继续接收上述信息，可以根据信息中提供的退订方式予以退订。
                <br>　　6.3 凡是超出与“个人信息的收集”条款声称目的具有直接或合理关联的范围使用您的个人信息，我们会再次向您告知并征得您的明示同意。
                <br>　　6.4 另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息而无需征求您的授权同意：
                <br>　　a) 与个人信息控制者履行法律法规规定的义务相关的；
                <br>　　b) 与国家安全、国防安全直接相关的；
                <br>　　c) 与公共安全、公共卫生、重大公共利益直接相关的；
                <br>　　d) 与刑事侦查、起诉、审判和判决执行等直接相关的；
                <br>　　e) 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
                <br>　　f) 所涉及的个人信息是个人信息主体自行向社会公众公开的；
                <br>　　g) 根据个人信息主体要求签订和履行合同所必需的；
                <br>　　h) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
                <br>　　i) 维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
                <br>　　j) 个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；
                <br>　　k) 个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
                <br>　　l) 其他法律法规等规定的情形。
                <br>　　6.5 我们不会公开或向第三方披露您的注册资料及使用职路数字平台时非公开的内容，但下列情况除外：
                <br>　　• 事先获得用户的明确授权；
                <br>　　• 根据有关的法律法规要求；
                <br>　　• 按照相关政府主管部门的要求；
                <br>　　• 为维护社会公众的利益；
                <br>　　• 为维护职路数字平台的合法权益；
                <br>　　• 本协议及职路数字平台相关规则中的特殊约定；
                <br>　　• 其他需要公开、编辑或透露个人信息的情况。
                <br>　　6.6 职路数字平台可能会与第三方合作向用户提供相关的服务，在此情况下，如第三方同意承担与职路数字平台同等的保护用户隐私的责任，则职路数字平台有权将相关的信息提供给第三方。
                <br>　　6.7 在不透露您的隐私资料的前提下，您同意我们对包含您的信息的整个用户数据库进行分析并对用户数据库进行利用。
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">四、企业授权条款</span>
                　　1、职路数字平台数字是一款基于全国人力资源一盘棋的基础用工即时共享平台，致力服务于劳动密集型企业的招聘难题。平台能安全高效的为企业提供即时的流动人才数据库，达成企业的免费招聘、精准招聘。结合平台共建共享的规则，为合法合规的实现这个目标，特此授权人力资源部在职路数字平台以在线签章的方式为本企业离职人员办理离职证明。
                <br>　　2、自企业入驻本平台之日起，则代表企业用户已对职路数字平台进行授权。
                <br>　　3、企业用户应及时在平台指定位置上传加盖企业公章的《授权书》和《扫码离职通知》，并在日常使用场景中合理公示已加盖公章的《授权书》和《扫码离职通知》。
                <br>　　4、免责条款：因企业未上传加盖公章的《授权书》或未在合理场景中公示《授权书》和《扫码离职通知》，所造成的一切后果由企业自身承担。
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">五、版权政策</span>
                　　除非职路数字平台另行声明，本网站的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图表、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密）及相关权利，均归深圳市职路信息技术有限公司所有，在此并未授权任何第三方予以使用、复制或储存。“职路”及相关图形等为深圳市职路信息技术有限公司的注册商标。<br>
                　　职路数字平台尊重知识产权，反对侵权盗版行为。未经职路数字平台许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用，或通过非常规方式（如：恶意干预职路数字平台数据）影响职路数字平台的正常服务，任何人不得擅自以软件程序自动获得职路数字平台数据。否则，职路数字平台有权依法追究其法律责任。
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">六、免责声明</span>
                　　1、您同意我们对以下事宜不承担任何法律责任：
                <br>　　• 非因职路数字平台原因导致的您的信息泄露和其他后果，例如由于您将您的账号和密码告知他人或与他人共享注册账户，由此导致您的信息泄露和其他后果；
                <br>　　• 职路数字平台根据法律规定或政府相关政策要求提供您的个人信息；
                <br>　　• 任何由于黑客攻击、病毒入侵而造成的暂时性软件关闭；
                <br>　　• 因不可抗力导致的任何后果；
                <br>　　• 因网络状况、通讯线路、第三方网站等任何原因而导致您不能正常使用职路数字平台；
                <br>　　• 职路数字平台在各服务条款及声明中列明的使用方式或免责情形。
                <br>　　2、您使用职路数字平台所查询的数据均来源于第三方，职路数字平台自身不控制、编辑或修改第三方网站上的信息的内容或其表现形式，仅对相关网站依法公示的信息如实展示。对于这些数据的真实性、完整性、及时性和准确性我们不作保证，因使用该第三方(包括其他用户)提供的内容或信息或参考该内容或信息而造成的损失由用户自负其责。另外，我们所提供的报告是根据现有已知的数据综合分析而得出相应的结论，仅为您的决策提供参考，对于您使用该报告而产生的任何后果，我们亦不承担责任。
                <br>　　3、是否使用本服务下载或取得任何资料应由您自行考量且自负风险，因任何资料之下载导致的您的电脑系统之任何损坏或数据流失等后果，由您自行承担。
                <br>　　4、您自职路数字平台或经由本服务取得的任何建议或信息，无论是书面或口头形式，除非本服务条款有明确规定，将不构成本服务条款之外之任何保证。
                <br>　　5、职路数字平台对由于用户不正当使用本网站服务、或依据本网站信息进行交易引起的对任何第三方的损害不承担任何赔偿责任。用户应对前述对职路数字平台或第三方的损害进行完全的赔偿。
                <br>　　6、本网站的内容及提供的服务不含任何明示性或暗示性的声明、保证或条件，包括但不限于关于真实性、适销性或适用于某一特定用途的明示或暗示性的声明、保证或条件，或者对其使用不会被中断或无误。
                <br>　　7、职路数字平台不声明或保证本网站或可从本网站下载的内容不带有计算机病毒或类似垃圾或破坏功能。
                <br>　　8、职路数字平台不担保本网站服务一定能满足用户的要求，不对页面内容的删除或储存失败负责。
                <br>　　9、若职路数字平台已经明示本网站运营方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。
                <br>　　10、用户确认其知悉，使用本网站提供的服务涉及到互联网服务和电信增值服务，可能会受到各个环节不稳定因素的影响。因此服务存在因国家相关行业主管部门及电信运营商的调整、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。用户须承担以上风险，职路数字平台不作担保。对因此导致用户不能发送、上传和接受阅读消息、或接发错消息，或无法实现其他通讯条件，职路数字平台不承担任何责任。
                <br>　　11、用户确认其知悉，在使用本网站提供的服务存在有来自任何其他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯(包括知识产权)的匿名或冒名的信息的风险，用户须承担以上风险，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、惩罚性的损害，不承担任何责任。
                <br>　　12、户明确同意其使用本网站服务所存在的风险将完全由其自己承担；因其使用本网站服务而产生的一切后果也由其自己承担，职路数字平台不对用户承担任何责任。
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">七、禁止事项 </span>
                　　如果您同意该项协议，则默认您承诺不做以下行为：
                <br>　　• 为任何非法目的而使用职路数字平台网络服务系统；
                <br>　　• 利用职路数字平台从事以下活动：
                <br>　　　a) 未经书面允许，进入计算机信息网络或者使用计算机信息网络资源的；
                <br>　　　b) 未经书面允许，对计算机信息网络功能进行删除、修改或者增加的；
                <br>　　　c) 未经书面允许，进入计算机信息网络中对存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
                <br>　　　d) 故意制作、传播计算机病毒（包括木马）等破坏性程序；
                <br>　　　e) 其他危害计算机信息网络安全的行为。
                <br>　　• 对职路数字平台相关服务任何部分或本服务之使用或获得，进行复制、出售、转售或用于任何其它商业目的。<br><br>

                　　用户需对其在使用职路数字平台提供数据信息服务过程中的行为承担完全的法律责任。如果职路数字平台发现其网站传输的信息明显属于本条规定的内容之一，或职路数字平台合理地认为用户的行为可能违反相关法律法规或本协议的规定，职路数字平台可以在任何时候、不经事先通知终止本协议，禁止相关用户访问职路数字平台提供数据信息，保存有关记录，向有权机关报告，并且删除含有该内容的地址、目录或关闭服务器。用户使用职路数字平台提供的其他数据信息的电子服务，也须遵守本协议的规定。<br>
                　　因用户提供的内容违法或侵犯第三人的合法权益而导致职路数字平台对第三方承担任何性质的赔偿、补偿或罚款而遭受损失(包括但不限于直接的、间接的、偶然的、惩罚性的损失)，用户对于职路数字平台遭受的上述损失承担完全的赔偿责任。
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">八、权利通知</span>
                　　1、职路数字平台一贯高度重视知识产权、商业秘密与个人隐私的保护并遵守中国各项法律、法规和具有约束力的规范性文件。根据法律、法规和规范性文件要求，职路数字平台制定了旨在保护权利人的合法权益的措施和步骤，当权利人发现在职路数字平台生成的信息所展示的内容侵犯其合法权益时，权利人应事先向职路数字平台发出书面的“权利通知”，职路数字平台将根据中国法律法规和政府规范性文件采取措施移除相关内容或以其他技术手段加以处理。若关联方未以该方式发送权利声明，则职路数字平台有权拒绝对网站所示数据与信息的修改或技术处理。
                <br>　　2、如果您所发出的权利通知的陈述失实，权利通知提交者将承担对由此造成的全部法律责任（包括但不限于赔偿各种费用及律师费）。如果您无法确定网络上可获取的资料侵犯了其何种法定权利，职路数字平台建议您首先咨询专业人士。
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">九、举报、投诉方式 </span>
                　　任何人士（特别是用户）发现职路数字平台有任何违反本协议及职路数字平台其他业务规则之规定的行为，可向职路数字平台举报或投诉，具体方式如下：
                <br> 　　举报、投诉电话：（0755）8652 9882
                <br> 　　收件地址：深圳南山区马家龙体育文化中心B栋11楼
                <br> 　　收件单位（部门）：深圳市职路信息技术有限公司
                <br> 　　电子邮箱：master@zhiluinfo.com
            </p>
            <p class="pdttb20">
                <span class="regprotocol-ftitle">十、法律管辖与适用 </span>
                　　1、本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律；您同意有关本协议以及使用职路数字平台的服务产生的争议交由深圳市职路信息技术有限公司注册地法院以诉讼方式解决。
                <br> 　　2、您理解并同意，根据服务的升级，我们有权对本协议进行更改。我们会在我们的网站及相关软件产品中公告相关的更改，请您随时予以关注。在本协议修改之后，您继续使用职路数字平台及相关服务的行为，将表示您已经同意并接受了本协议的更改。
                <br> 　　3、本协议条款的最终解释权归深圳市职路信息技术有限公司所有。
            </p>
        </div>
    </div>
    <!-- eslint-enable -->

</template>

<script>
/* eslint-disable */
export default {
    name: '',
    data() {
        return {
            mobile: false,
            tableData: [
                {
                    auth: '位置',
                    function: '核心业务功能',
                    objective: '获取用户当前位置，方便用户寻找附近工作',
                    inquiry: '首次打开手机客户端时弹窗询问',
                    closed: '是',
                },
                {
                    auth: '短信',
                    function: '登陆账号',
                    objective: '登陆时自动获取验证码',
                    inquiry: '首次登录手机客户端时弹窗询问',
                    closed: '是',
                },
                {
                    auth: '相机',
                    function: '扫码识别',
                    objective: '识别二维码',
                    inquiry: '首次使用相应功能时弹窗询问',
                    closed: '是',
                },
                {
                    auth: '电话',
                    function: '核心业务功能',
                    objective: '求职者与企业之间联系',
                    inquiry: '首次使用相应功能时弹窗询问',
                    closed: '是',
                },
                {
                    auth: '存储',
                    function: '存储',
                    objective: '存储用户信息、存储日志等信息',
                    inquiry: '首次打开手机客户端时弹窗',
                    closed: '是',
                },
                {
                    auth: '存储文件',
                    function: '存储图片',
                    objective: '让用户下载存储回执、海报等图片文字',
                    inquiry: '首次打开手机客户端时弹窗',
                    closed: '是',
                },
                {
                    auth: '开启移动数据',
                    function: '全部功能',
                    objective: '连接网络',
                    inquiry: '程序启动时检测网络状态，弹窗询问',
                    closed: '是',
                },
                {
                    auth: '开启WLAN',
                    function: '全部功能',
                    objective: '连接网络',
                    inquiry: '程序启动时检测网络状态，弹窗询问',
                    closed: '是',
                },
                {
                    auth: '通知',
                    function: '发送push通知',
                    objective: '发送消息',
                    inquiry: '否',
                    closed: '是',
                },
            ],
            sdkTableData: [
                {
                    auth: 'com.igexin.sdk',
                    function: '个推推送',
                    objective: '无',
                    inquiry: '进行消息通知推送',
                },
                {
                    auth: 'com.g.gysdk',
                    function: '个推',
                    objective: '无',
                    inquiry: '进行消息通知推送',
                },
                {
                    auth: 'com.xiaomi.mipush',
                    function: '小米推送',
                    objective: '无',
                    inquiry: '进行消息通知推送',
                },
                {
                    auth: 'com.xiaomi.push',
                    function: '小米推送',
                    objective: '无',
                    inquiry: '进行消息通知推送',
                },
                {
                    auth: 'com.getui',
                    function: '个推',
                    objective: '无',
                    inquiry: '进行消息通知推送',
                },
                {
                    auth: 'com.amap.api',
                    function: '高德地图',
                    objective: '定位',
                    inquiry: '根据已获得的用户位置，进行计算距离及导航等功能',
                },
            ]
        }
    },
    created() {
        this.is_mobile()
    },
    methods: {
        is_mobile() {
            var regex_match = /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
            var u = navigator.userAgent;
            if (null == u) {
                return true;
            }
            var result = regex_match.exec(u);

            if (null == result) {
                // return false
            } else {
                this.mobile = true
                // return true
            }
        }
    }
}
/* eslint-enable */
</script>
<style >
html,
body {
    min-width: 100% !important;
}
</style>
<style lang='less' scoped>
.page {
    background: #f4f4f4;
    .regprotocol {
        // display: table;
        margin: 0 auto;
        width: 56vw;
        padding: 40px 70px 160px;
        background-color: #fff;
        color: #353637 !important;
        line-height: 2;
    }
    .regprotocol-title {
        text-align: center;
        font-size: 16px;
    }
    .pdttb20 {
        padding: 20px 0;
    }
    .regprotocol-ftitle {
        display: block;
        padding-bottom: 10px;
    }
}
.mobile {
    background: #f4f4f4;
    .regprotocol {
        // display: table;
        margin: 0 auto;
        width: 100%;
        // padding: 40px 70px 160px;
        padding: 20px;
        background-color: #fff;
        color: #353637 !important;
        line-height: 2;
    }
    .regprotocol-title {
        text-align: center;
        font-size: 14px !important;
    }
    .pdttb20 {
        padding: 10px 0;
        font-size: 12px;
    }
    .regprotocol-ftitle {
        display: block;
        padding-bottom: 10px;
    }
}
</style>